.header {
    min-height: calc(100vh - 99rem);
    background-image: url("/assets/FotoVillaMagnolia/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    flex-direction: row-reverse;
}

.cardHeader {
}


.logoMagnolia {
    width: 80%!important;
    filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg) brightness(102%) contrast(143%);
}
