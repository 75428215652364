
td,th {
    text-align:center;

    font-weight: 400;
    font-size: 14px;
    color: #555555;
    width: 2em;

    /* border-radius: 15%; */
    margin: 0.08em;
}
td:first-child {
    color: rgb(189, 55, 55);
}
.Stile2 {color: #800000}

.option {
    background: #C0EAFF;
}
.confirmed {
    background: #FFB9B9 ;
}

td.outer {

    border : solid #dddddd;
    text-align: center;
}

table.inner {

}

/* OLD */
/* NEW */

.cal {
    float: left;
    height: 180px;
    padding: 1em;
    border: solid 1px #eee;
    margin: 1.535em;
}

.cal2 {
    line-height: 22px;
    float: left;
    height: 180px;
    padding: 1em 0 1em 0;
}
