.header {
    min-height: calc(100vh - 9rem);
    background-image: url("/assets/layout/images/housesketch.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.cardHeader {
}
